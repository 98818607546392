<template>
  <!-- если карточка есть -->
  <div class="card-have" v-if="item">
    <!-- card content -->
    <div class="card-have__container">
      <!-- card img block -->
      <div class="card-have__container-imgBlock" v-if="item.picture">
        <img :src="item.picture" alt="avatar" width="50" height="50" />
      </div>
      <!-- card text block -->
      <div :class="['card-have__container-textBlock', { compress: item.picture }]">
        <div class="card-have__wrapper-top">
          <h5 class="title title--h5">{{ item.name }}</h5>
          <div class="card-have__btns" v-if="!isReadonly">
            <IconComponent class="icon--full-svg" @click.prevent.stop="editCard()" name="edit" />
            <IconComponent class="icon--full-svg delete" @click.prevent.stop="deleteCard()" name="delete-not-fill" />
          </div>
        </div>
        <!-- <p class="category"> {{ item.participantCategory ? item.participantCategory.name : "" }} {{ getPartName(item.countryId) }} </p> -->
        <p>{{ participantCategoryName }} {{ getPartName(item.countryId) }}</p>
        <p>{{ item.position }}</p>
        <p v-if="item.phone && item.phone.length > 0" class="phone">
          Телефон приемной:
          <br />
          {{ item.phone }}
        </p>
        <p v-if="item.infoLink">
          <a target="_blank" :title="item.infoName" :href="item.infoLink">{{ item.infoName }}</a>
        </p>
      </div>
    </div>
  </div>

  <!-- если карточка пустая -->
  <div class="card-empty" v-else>
    <!-- <p class="mb-6">{{ participantCategoryName }} {{ getPartName(countryId) }}</p> -->
    <div class="btn-add-container" v-if="!isReadonly">
      <button class="btn-add-block" @click.prevent.stop="chooseCard()">
        <IconComponent class="mb-10" name="add_box" />
        <p>Выбрать или создать карточку участника<br />{{ participantCategoryName }} {{ getPartName(countryId) }}</p>
      </button>
    </div>
    <div class="empty" v-else>(уточняется)</div>
  </div>

  <!-- модалка для заполнения -->
  <section>
    <ModalComponent headType="blue" v-model="showModal" title="Выбор участника" @close="close">
      <CommissionParticipantChoose
        v-bind:participantCategoryId="participantCategoryId"
        v-bind:participantCategoryName="participantCategoryName"
        v-bind:countryId="countryId"
        v-bind:countryName="countryName"
        v-bind:value="item"
        v-bind:nn="nn"
        v-on:close="close"
        v-on:save="save($event)"
        :showPhoto="showPhoto"
      />
    </ModalComponent>
  </section>
</template>

<script>
  import Constants from '@/common/constants';
  // import ModalComponent from "../../../common/ui/ModalComponent";
  import IconComponent from '@/common/ui/IconComponent.vue';

  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import CommissionParticipantChoose from '../components/CommissionParticipantChoose.vue';

  export default {
    name: 'CommissionParticipantBlock',
    components: {
      CommissionParticipantChoose,
      ModalComponent,
      IconComponent,
    },
    props: {
      modelValue: Object,
      isReadonly: Boolean,
      participantCategoryId: Number,
      countryId: Number,
      participantCategoryName: String,
      countryName: String,
      isRG: {
        type: Boolean,
        default: false,
      },
      showPhoto: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        item: this.modelValue,
        showModal: false,
        countryRussiaId: Constants.countryRussiaId,
        nn: 0,
      };
    },
    created() {},
    methods: {
      getPartName(countryId) {
        var suffix = this.isRG ? 'РГ' : 'МПК';
        return (countryId == this.countryRussiaId ? 'российской части' : 'иностранной части') + ' ' + suffix;
      },
      chooseCard() {
        this.nn++;
        this.showModal = true;
      },
      deleteCard() {
        this.item = null;
        this.$emit('update:modelValue', this.item);
      },
      editCard() {
        this.nn++;
        this.showModal = true;
        this.$emit('update:modelValue', this.item);
      },
      save(item) {
        this.showModal = false;
        this.item = item;
        this.$emit('update:modelValue', this.item);
      },
      close() {
        this.showModal = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';
  .card {
    &-have {
      background: $grey-disabled;
      border-radius: 4px;
      padding: 12px 12px 4px;
      min-height: 100%;
      &__btns {
        display: flex;
        justify-content: flex-end;

        .icon {
          color: $base;
          width: 16px;
          height: 16px;
          transition: 0.3s ease;
          &:last-child {
            margin-left: 12px;
          }

          &:hover,
          &:active {
            color: $blue-2;

            &.delete {
              color: $red;
            }
          }
        }
      }

      &__wrapper-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 8px;
      }

      &__container {
        display: flex;
        align-items: flex-start;

        &-imgBlock {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 8px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        &-textBlock {
          font-family: 'Fira Sans', sans-serif;
          width: 100%;

          &.compress {
            width: calc(100% - 58px);
          }

          p {
            font: $fira-12;
            margin-bottom: 8px;
          }

          a {
            font: $fira-12;
          }

          .category {
            font-weight: 500;
          }

          .category,
          .position,
          .phone {
            font-size: 12px;
            margin-bottom: 4px;
          }
        }
      }

      @media (max-width: $laptop) {
        &__container {
          flex-wrap: wrap;

          &-textBlock {
            &.compress {
              width: 100%;
            }
          }

          &-imgBlock {
            margin: 0 0 8px;
          }
        }

        &__btns {
          .icon {
            &:last-child {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .btn-add-block {
    min-height: 128px;

    @media (max-width: $laptop) {
      min-height: 160px;
    }
  }
  .nophoto {
    background-image: url(~@/assets/images/no_photo.png);
    color: black;
  }
  .imagePreviewWrapper {
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
  }

  .empty {
    font: $fira-12;
  }
</style>
